<template>
  <div class="operation-log-container">
    <div class="addadvBox">
      <HomeTitle title="数据模块名称" borderColor="blue" />
      <a-button type="primary" style="background: #345bff">
        <router-link :to="{ name: 'directpassenger' }">创建广告</router-link>
      </a-button>
    </div>
    <div class="left">
      <a-table rowKey="click" :columns="columns" :pagination="false" :data-source="tableData" bordered></a-table>
    </div>
  </div>
</template>
<script>
import HomeTitle from '../components/homeTitle'
import mixDate from '@/mixins/initDate'
import { topAdplan } from '@/api/agentadvertiser'
export default {
  components: { HomeTitle },
  mixins: [mixDate],
  data () {
    return {
      query: {
        startDate: '',
        endDate: ''
      },
      tableData: [],
      columns: [
        {
          title: '计划名称',
          dataIndex: 'adplanName',
          key: 'adplanName'
        },
        {
          title: '展示量',
          dataIndex: 'impress',
          key: 'impress'
        },
        {
          title: '点击量',
          key: 'click',
          dataIndex: 'click'
        }
      ]
    }
  },
  mounted () {
    const { endDate, startDate } = this.initDate(0, 0)
    this.query.startDate = startDate
    this.query.endDate = endDate
    this.topAdplanFn(this.query)
  },
  methods: {
    async topAdplanFn (data) {
      const resp = await topAdplan(data)
      if (resp.code === 200 && resp.data) {
        this.tableData = resp.data.advertiserPlanList || []
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep.operation-log-container {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .addadvBox {
    display: flex;
    justify-content: space-between;
    align-content: center;
    text-align: right;
    padding: 18px;
  }
  .left {
    padding: 4px 0px 4px 10px;
    margin: 5px 5px;
    .ant-table-placeholder {
      border-bottom: none;
    }
    .ant-table-thead > tr > th {
      border-bottom: none;
    }
  }
}
</style>
