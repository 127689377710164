<template>
  <div class="home-container">
    <div class="home_header">
      <!-- 头部card -->
      <RealTimeCard />
    </div>
    <div class="home_main">
      <div class="home_left">
        <div class="home_main_top home_main_top_aside">
          <!-- top广告平台 -->
          <OperationLog />
        </div>
      </div>
      <div class="home_right" style="flex: 1">
        <div class="home_main_top home_main_top_aside">
          <!-- 操作日志 -->
          <DataIndicator />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RealTimeCard from './realTimeCard'
import OperationLog from './operationLog'
import DataIndicator from './dataIndicator'
export default {
  name: 'Home',
  components: { RealTimeCard, OperationLog, DataIndicator }
}
</script>

<style lang="less" scoped>
.home-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  .home_header {
    position: relative;
    padding: 0 0 5px 0;
  }
  .home_main {
    position: relative;
    width: 100%;
    display: flex;
    .home_center {
      min-width: 600px;
      max-width: 900px;
      order: 1;
      padding: 5px 5px 0 5px;
    }
    .home_left {
      order: 2;
      min-width: 450px;
      max-width: 489px;
      width: 25%;
      order: 0;
      padding: 5px 5px 0 0;
    }
    .home_right {
      height: 100%;
      order: 2;
      min-width: 800px;
      width: 75%;
      order: 2;
      padding: 5px 0 0 5px;
    }
    div[class^='home_main_'] {
      width: 100%;
      position: relative;
    }
    .home_main_top {
      padding-bottom: 5px;
      // &.home_main_top_center {
      //   aspect-ratio: 2.571;
      // }
      // &.home_main_top_aside {
      //   aspect-ratio: 1.8;
      // }
      height: 660px;
    }
    .home_main_bottom {
      padding-top: 5px;
      // &.home_main_top_aside {
      //   aspect-ratio: 0.9607;
      // }
      // &.home_main_top_center {
      //   aspect-ratio: 1.7647;
      // }
      height: 700px;
    }
  }
}
</style>
